import { SUPPORTED_CHAINS, CHAINS } from "./../constants";
import walletConnectLogo from "../assets/walletconnect-logo.png";
import { IAppConfig } from "../helpers/types";

const appConfig: IAppConfig = {
  name: "WalletConnect",
  logo: walletConnectLogo,
  chainId: CHAINS.RINKEBY,
  colors: {
    defaultColor: "12, 12, 13",
    backgroundColor: "40, 44, 52",
  },
  chains: SUPPORTED_CHAINS,
};

export function getAppConfig(): IAppConfig {
  return appConfig;
}
