import { IChainData } from "../helpers/types";
import { API_KEY_TOKEN } from "./default";

export enum CHAINS {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  BSC = 56,
  POLYGON = 137,
}

export const SUPPORTED_CHAINS: IChainData[] = [
  {
    name: "Ethereum Mainnet",
    short_name: "eth",
    chain: "ETH",
    network: "mainnet",
    chain_id: CHAINS.MAINNET,
    network_id: CHAINS.MAINNET,
    rpc_url: "https://mainnet.infura.io/v3/%API_KEY%",
    explorer_url: "https://etherscan.io/tx/%txHash%",
  },
  {
    name: "Ethereum Rinkeby",
    short_name: "rin",
    chain: "ETH",
    network: "rinkeby",
    chain_id: CHAINS.RINKEBY,
    network_id: CHAINS.RINKEBY,
    rpc_url: "https://rinkeby.infura.io/v3/%API_KEY%",
    explorer_url: "https://rinkeby.etherscan.io/tx/%txHash%",
  },
  {
    name: "Binance Smart Chain",
    short_name: "bsc",
    chain: "BSC",
    network: "Smart Chain",
    chain_id: CHAINS.BSC,
    network_id: CHAINS.BSC,
    rpc_url: "https://bsc-dataseed.binance.org",
    explorer_url: "https://bscscan.com/tx/%txHash%",
  },
];

export const rpcUrlGetterByNetwork: {
  [key in CHAINS]: null | ((token?: string) => string);
} = {
  [CHAINS.MAINNET]: token => `https://mainnet.infura.io/v3/${token}`,
  [CHAINS.ROPSTEN]: null,
  [CHAINS.RINKEBY]: token => `https://rinkeby.infura.io/v3/${token}`,
  [CHAINS.BSC]: () => "https://bsc-dataseed.binance.org",
  [CHAINS.POLYGON]: () => "https://rpc-mainnet.matic.network",
};

export const abiUrlGetterByNetwork: {
  [key in CHAINS]?: ((address: string) => string) | null;
} = {
  [CHAINS.MAINNET]: (address: string) =>
    `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${API_KEY_TOKEN}`,
  [CHAINS.ROPSTEN]: null,
  [CHAINS.RINKEBY]: (address: string) =>
    `https://api-rinkeby.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${API_KEY_TOKEN}`,
  [CHAINS.BSC]: (address: string) =>
    `https://api.bscscan.com/api?module=contract&action=getabi&address=${address}&apikey=${API_KEY_TOKEN}`,
  [CHAINS.POLYGON]: (address: string) =>
    `https://api.polygonscan.com/api?module=contract&action=getabi&address=${address}&apikey=${API_KEY_TOKEN}`,
};
