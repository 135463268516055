import { IChainData, IRequestRenderParams } from "./types";
import { SUPPORTED_CHAINS, REACT_APP_INFURA_PROJECT_ID } from "../constants";
import { convertHexToNumber } from "@walletconnect/utils";
import Web3 from "web3";
import InterfaceRepository from "../services/interfaceRepository";
// @ts-ignore
import * as abiDecoder from "abi-decoder";

export function capitalize(string: string): string {
  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function ellipseText(text = "", maxLength = 9999): string {
  if (text.length <= maxLength) {
    return text;
  }
  const _maxLength = maxLength - 3;
  let ellipse = false;
  let currentLength = 0;
  const result =
    text
      .split(" ")
      .filter(word => {
        currentLength += word.length;
        if (ellipse || currentLength >= _maxLength) {
          ellipse = true;
          return false;
        } else {
          return true;
        }
      })
      .join(" ") + "...";
  return result;
}

export function ellipseAddress(address = "", width = 10): string {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export function getChainData(chainId: number): IChainData {
  const chainData = SUPPORTED_CHAINS.filter((chain: any) => chain.chain_id === chainId)[0];

  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const API_KEY = process.env.REACT_APP_INFURA_PROJECT_ID || REACT_APP_INFURA_PROJECT_ID;

  if (!API_KEY) {
    throw new Error("Environment variable REACT_APP_INFURA_PROJECT_ID is not set");
  }

  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);

    if (!rpcUrl) {
      throw Error(`RPC URL not defined for chain id ${chainId}`);
    }

    return {
      ...chainData,
      rpc_url: rpcUrl,
    };
  }

  return chainData;
}

export function getCachedSession(): any {
  const local = localStorage ? localStorage.getItem("walletconnect") : null;

  let session = null;
  if (local) {
    try {
      session = JSON.parse(local);
    } catch (error) {
      throw error;
    }
  }
  return session;
}

export function renderEthereumRequests(payload: any): IRequestRenderParams[] {
  let params = [{ label: "WalletConnect Method", value: payload.method }];

  const { decodedData } = payload.params[0];
  const decodedDataParams = decodedData.params.map((param: any, index: number) => {
    const formattedParam = decodedData.formattedParams[index];

    const paramValues = Object.keys(param.value)
      .filter(value => {
        return isNaN(Number(value));
      })
      .map(key => {
        return {
          label: `${key}(${formattedParam.paramTypeMap[key]})`,
          value: param.value[key],
        };
      });

    return {
      label: `${param.name}(${formattedParam.types.join(",")})`,
      value:
        paramValues.length > 0
          ? paramValues
          : Array.isArray(param.value)
          ? param.value.join(",")
          : param.value,
    };
  });

  switch (payload.method) {
    case "eth_sendTransaction":
      params = [
        ...params,
        { label: "From", value: payload.params[0].from },
        { label: "To", value: payload.params[0].to },
        {
          label: "Gas Limit",
          value: payload.params[0].gas
            ? convertHexToNumber(payload.params[0].gas)
            : payload.params[0].gasLimit
            ? convertHexToNumber(payload.params[0].gasLimit)
            : "",
        },
        {
          label: "Value",
          value: payload.params[0].value ? convertHexToNumber(payload.params[0].value) : "0",
        },
        { label: "Data", value: payload.params[0].data },
        { label: "Contract Interaction", value: decodedData.name },
        ...decodedDataParams,
      ];
      break;
    default:
      params = [
        ...params,
        {
          label: "params",
          value: JSON.stringify(payload.params, null, "\t"),
        },
      ];
      break;
  }
  return params;
}

export const getAbiParamFromEncodedData = async (
  chainId: number,
  address: string,
  paramData: string,
) => {
  try {
    const chain = getChainData(chainId);

    const web3Instance = new Web3(chain.rpc_url);
    const interfaceRepo = new InterfaceRepository(chainId, web3Instance);
    const { methods: abiList } = await interfaceRepo.loadAbi(address);

    abiDecoder.addABI(abiList);
    const decodedData = abiDecoder.decodeMethod(paramData);

    const interactedAbi = abiList.find(abiItem => abiItem.name === decodedData.name);

    const result = interactedAbi?.inputs.map((input, index) => {
      if (input.type === "tuple") {
        return input.components.reduce(
          (result: any, component: any) => {
            result.types.push(component.type);
            result.paramTypeMap[component.name] = component.type;
            return result;
          },
          { types: [], paramTypeMap: {} },
        );
      } else {
        return {
          types: [input.type],
          paramTypeMap: {
            [input.name]: input.type,
          },
        };
      }
    });

    decodedData.formattedParams = result;

    console.log("interactedAbi", interactedAbi);
    console.log("decodedData", decodedData);

    return decodedData;
  } catch (e) {
    console.error(e);
    return {};
  }
};
