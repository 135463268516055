import axios from "axios";
import memoize from "lodash/memoize";

import { CHAINS, abiUrlGetterByNetwork } from "../constants/chains";

export interface ContractMethod {
  inputs: any[];
  name: string;
  payable: boolean;
}

export interface ContractInterface {
  methods: ContractMethod[];
}

const getAbi = memoize(async (apiUrl: string) => axios.get(apiUrl));

class InterfaceRepository {
  private chainId: CHAINS;
  private web3: any;

  constructor(chainId: CHAINS, web3: any) {
    this.chainId = chainId;
    this.web3 = web3;
  }

  public async loadAbi(addressOrAbi: string): Promise<ContractInterface> {
    const abiString = this.web3.utils.isAddress(addressOrAbi)
      ? await this._loadAbiFromBlockExplorer(addressOrAbi)
      : addressOrAbi;

    const abi = JSON.parse(abiString);

    console.log("origin abi: ", abi);

    const methods = abi
      .filter((e: any) => {
        if (["pure", "view"].includes(e.stateMutability)) {
          return false;
        }

        if (e?.type.toLowerCase() === "event") {
          return false;
        }

        return !e.constant;
      })
      .filter((m: any) => m.type !== "constructor")
      .map((m: any) => {
        return {
          inputs: m.inputs || [],
          name: m.name || (m.type === "fallback" ? "fallback" : "receive"),
          payable: this._isMethodPayable(m),
        };
      });

    console.log(methods);

    return { methods };
  }

  private async _loadAbiFromBlockExplorer(address: string): Promise<string> {
    const getAbiUrl = abiUrlGetterByNetwork[this.chainId];
    if (!getAbiUrl) {
      throw Error(`Chain id: ${this.chainId} not supported.`);
    }

    const abi = await getAbi(getAbiUrl(address));
    if (abi.data.status !== "1") {
      throw Error(`Request not successful: ${abi.data.message}; ${abi.data.result}.`);
    }
    return abi.data.result;
  }

  private _isMethodPayable = (m: any) => m.payable || m.stateMutability === "payable";
}

export type InterfaceRepo = InstanceType<typeof InterfaceRepository>;

export default InterfaceRepository;
