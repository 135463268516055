import * as React from "react";
import Web3 from "web3";
import * as ethers from "ethers";
import styled from "styled-components";
import WalletConnect from "@walletconnect/client";
import Button from "./components/Button";
import Card from "./components/Card";
import Input from "./components/Input";
import Header from "./components/Header";
import Column from "./components/Column";
import RequestDisplay from "./components/RequestDisplay";
import AccountDetails from "./components/AccountDetails";
import {
  getCachedSession,
  renderEthereumRequests,
  getChainData,
  getAbiParamFromEncodedData,
} from "./helpers/utilities";
import { getAppConfig } from "./config";
import CoboSafe from "./abis/CoboSafe";
import { setLocal, getLocal, removeLocal } from "./helpers/local";
import qs from "querystringify";
import { Spin, notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

declare let window: any;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0;
`;

const SContent = styled.div`
  width: 100%;
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
`;

const SLogo = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  max-height: 100px;
  & img {
    width: 60%;
  }
`;

const SSection = styled.div`
  width: 100%;
`;

const SActions = styled.div`
  margin: 0;
  margin-top: 20px;

  display: flex;
  justify-content: space-around;
  & > * {
    margin: 0 5px;
  }
`;

const SActionsColumn = styled(SActions as any)`
  flex-direction: row;
  align-items: center;

  margin: 24px 0 6px;

  & > * {
    margin: 0;
  }

  & > p {
    font-weight: 600;
  }
`;

const SInput = styled(Input)`
  width: 100%;
  font-size: 14px;
  height: 40px;
`;

const SConnectedPeer = styled.div`
  display: flex;
  align-items: center;
  & img {
    width: 40px;
    height: 40px;
  }
  & > div {
    margin-left: 10px;
  }
`;

export interface IAppState {
  loading: boolean;
  connector: WalletConnect | null;
  uri: string;
  peerMeta: {
    description: string;
    url: string;
    icons: string[];
    name: string;
    ssl: boolean;
  };
  connected: boolean;
  chainId: number;
  accounts: string[];
  address: string;
  moduleAddress: string;
  results: any[];
  payload: any;
}

// export const DEFAULT_ADDRESS = "0x0dD9b5cfadF6599B0f634336eCe3Fb77739C52fb";
// export const SAFE_MODULE_ADDRESS = "0xFc9B862039918393141E57a643316cE52e1c40fe";
// export const DEFAULT_ACCOUNTS = [DEFAULT_ADDRESS];

export const INITIAL_STATE: IAppState = {
  loading: false,
  connector: null,
  uri: "",
  peerMeta: {
    description: "",
    url: "",
    icons: [],
    name: "",
    ssl: false,
  },
  connected: false,
  chainId: getAppConfig().chainId,
  accounts: [],
  address: "",
  moduleAddress: "",
  results: [],
  payload: null,
};

class App extends React.Component<{}> {
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  public componentDidMount() {
    this.init();

    const result: any = qs.parse(window.location.search);
    console.log(result);

    this.setState({
      address: result.safeAddress,
      moduleAddress: result.moduleAddress,
    });
  }

  public init = async () => {
    const session = getCachedSession();

    if (session) {
      const connector = new WalletConnect({ session });
      const { connected, accounts, peerMeta } = connector;
      const moduleAddress = getLocal("moduleAddress");

      await this.setState({
        connected,
        connector,
        accounts,
        chainId: connector.chainId,
        peerMeta,
        moduleAddress,
      });
      this.subscribeToEvents();
    }
  };

  public initWalletConnect = async () => {
    const { uri } = this.state;

    await this.setState({ loading: true });

    try {
      const connector = new WalletConnect({ uri });
      if (!connector.connected) {
        await connector.createSession();
      }

      await this.setState({
        connector,
        uri: connector.uri,
      });

      this.subscribeToEvents();
    } catch (error) {
      this.setState({ loading: false });

      throw error;
    }
  };

  public approveSession = () => {
    console.log("ACTION", "approveSession");
    const { connector, chainId, address } = this.state;
    if (connector) {
      connector.approveSession({
        chainId,
        accounts: [address],
      });
    }
    this.setState({ connector });
  };

  public rejectSession = () => {
    console.log("ACTION", "rejectSession");
    const { connector } = this.state;
    if (connector) {
      connector.rejectSession();
    }
    this.setState({ connector });
  };

  public killSession = async () => {
    console.log("ACTION", "killSession");
    const { connector } = this.state;
    if (connector) {
      connector.killSession();
    }
    this.resetApp();
  };

  public resetApp = async () => {
    await this.setState({ ...INITIAL_STATE });
    removeLocal("moduleAddress");
  };

  public subscribeToEvents = () => {
    console.log("ACTION", "subscribeToEvents");
    const { connector } = this.state;

    if (connector) {
      connector.on("session_request", async (error, payload) => {
        console.log("EVENT", "session_request");

        if (error) {
          throw error;
        }
        console.log("SESSION_REQUEST", payload.params);
        const { peerMeta, chainId } = payload.params[0];
        await this.setState({ peerMeta, chainId, loading: false });
        this.approveSession();

        setLocal("moduleAddress", this.state.moduleAddress);
      });

      connector.on("session_update", error => {
        console.log("EVENT", "session_update");

        if (error) {
          throw error;
        }
      });

      connector.on("call_request", async (error, payload) => {
        console.log("EVENT", "call_request", "method", payload.method);
        console.log("EVENT", "call_request", "params", payload.params);
        console.log("payload: ", payload);

        if (error) {
          throw error;
        }

        this.openRequest(payload);
      });

      connector.on("connect", (error, payload) => {
        console.log("EVENT", "connect");

        if (error) {
          throw error;
        }

        this.setState({ connected: true });
      });

      connector.on("disconnect", (error, payload) => {
        console.log("EVENT", "disconnect");

        if (error) {
          throw error;
        }

        this.resetApp();
      });

      if (connector.connected) {
        const { chainId, accounts } = connector;
        const address = accounts[0];
        this.setState({
          connected: true,
          address,
          chainId,
        });
      }

      this.setState({ connector });
    }
  };

  public updateSession = async (sessionParams: { chainId?: number; address?: string }) => {
    const { connector, chainId, address } = this.state;
    const newChainId = sessionParams.chainId || chainId;
    const newAddress = sessionParams.address || address;
    if (connector) {
      connector.updateSession({
        chainId: newChainId,
        accounts: [newAddress],
      });
    }
    await this.setState({
      connector,
      chainId: newChainId,
      address: newAddress,
    });
  };

  public updateChain = async (chainId: number | string) => {
    await this.updateSession({ chainId: Number(chainId) });
  };

  public updateAddress = async (address: string) => {
    if (address) {
      await this.updateSession({ address });
    } else {
      await this.setState({
        address,
      });
    }
  };

  public updateModuleAddress = async (moduleAddress: string) => {
    await this.setState({
      moduleAddress,
    });
  };

  public onURIPaste = async (e: any) => {
    const data = e.target.value;
    const uri = typeof data === "string" ? data : "";
    if (uri) {
      await this.setState({ uri });
    }
  };

  public openRequest = async (request: any) => {
    const payload = Object.assign({}, request);

    const params = payload.params[0];

    params.from = ethers.utils.getAddress(params.from);
    params.to = ethers.utils.getAddress(params.to);

    params.decodedData = await getAbiParamFromEncodedData(
      this.state.chainId,
      params.to,
      params.data,
    );

    this.setState({
      payload,
    });
  };

  public closeRequest = async () => {
    await this.setState({
      payload: null,
    });
  };

  public approveRequest = async () => {
    const { connector, payload, chainId } = this.state;

    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const params = payload.params[0];

        const web3 = new Web3(window.ethereum);
        const coboSafeIns = new web3.eth.Contract(
          // @ts-ignore
          CoboSafe,
          this.state.moduleAddress,
        );

        notification.info({
          message: "处理中",
          description: <Spin spinning={true} />,
          duration: 120,
        });
        this.closeRequest();

        const result = await coboSafeIns.methods
          .execTransaction(params.to, params.data)
          .send({ from: accounts[0] });

        if (connector) {
          connector.approveRequest({
            id: payload.id,
            result: result.transactionHash,
          });
        }

        notification.destroy();

        console.log(result);

        const chain = getChainData(chainId);
        const txUrl = chain.explorer_url.replace("%txHash%", result.transactionHash);

        Modal.success({
          title: "Success",
          content: (
            <a href={txUrl} target="_blank" rel="noopener noreferrer">
              在区块浏览器查看
            </a>
          ),
        });
      }
    } catch (error) {
      notification.destroy();
      Modal.error({
        title: "Error",
        content: error.message,
      });
      console.error(error);
      if (connector) {
        connector.rejectRequest({
          id: payload.id,
          error: { message: "Failed or Rejected Request" },
        });
      }
    }

    this.closeRequest();
    await this.setState({ connector });
  };

  public rejectRequest = async () => {
    const { connector, payload } = this.state;
    if (connector) {
      connector.rejectRequest({
        id: payload.id,
        error: { message: "Failed or Rejected Request" },
      });
    }
    await this.closeRequest();
    await this.setState({ connector });
  };

  public render() {
    const { peerMeta, connected, moduleAddress, address, chainId, payload, loading } = this.state;
    return (
      <React.Fragment>
        <SContainer>
          <Header
            connected={connected}
            address={address}
            chainId={chainId}
            killSession={this.killSession}
          />
          <SContent>
            <Card maxWidth={500}>
              <SLogo>
                <img src={getAppConfig().logo} alt={getAppConfig().name} />
              </SLogo>
              {!connected ? (
                <Column>
                  <Spin spinning={loading} indicator={LoadingIcon}>
                    <AccountDetails
                      chains={getAppConfig().chains}
                      address={address}
                      moduleAddress={moduleAddress}
                      chainId={chainId}
                      updateAddress={this.updateAddress}
                      updateChain={this.updateChain}
                      updateModuleAddress={this.updateModuleAddress}
                    />
                    <SSection>
                      <h6>{"Wallet Connect"}</h6>
                      <SInput
                        onChange={this.onURIPaste}
                        placeholder={"Paste WalletConnect QR code or connection URI"}
                      />
                    </SSection>
                  </Spin>
                  <SActions>
                    <Button
                      disabled={!(this.state.address && this.state.moduleAddress && this.state.uri)}
                      onClick={() => this.initWalletConnect()}
                    >
                      Connect
                    </Button>
                  </SActions>
                </Column>
              ) : !payload ? (
                <Column>
                  <AccountDetails
                    canEdit={false}
                    chains={getAppConfig().chains}
                    address={address}
                    moduleAddress={moduleAddress}
                    chainId={chainId}
                    updateChain={this.updateChain}
                  />
                  {peerMeta && peerMeta.name && (
                    <>
                      <h6>{"Connected to"}</h6>
                      <SConnectedPeer>
                        <img src={peerMeta.icons[0]} alt={peerMeta.name} />
                        <div>{peerMeta.name}</div>
                      </SConnectedPeer>
                    </>
                  )}
                </Column>
              ) : (
                <RequestDisplay
                  payload={payload}
                  peerMeta={peerMeta}
                  renderPayload={renderEthereumRequests}
                  approveRequest={this.approveRequest}
                  rejectRequest={this.rejectRequest}
                />
              )}
            </Card>
          </SContent>
        </SContainer>
      </React.Fragment>
    );
  }
}

export default App;
