import * as React from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import { IChainData } from "../helpers/types";
import Blockie from "./Blockie";
import Input from "./Input";

const SSection = styled.div`
  width: 100%;
`;

const SBlockie = styled(Blockie)`
  margin-right: 5px;
`;

const SAddressDropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SInput = styled(Input)`
  width: 100%;
`;

interface IAccountDetailsProps {
  chains: IChainData[];
  updateAddress?: any;
  updateModuleAddress?: any;
  updateChain?: any;
  address: string;
  chainId: number;
  moduleAddress: string;
  canEdit?: boolean;
}

const AccountDetails = (props: IAccountDetailsProps) => {
  const {
    chains,
    chainId,
    address,
    moduleAddress,
    updateChain,
    updateAddress,
    updateModuleAddress,
    canEdit = true,
  } = props;
  return (
    <React.Fragment>
      <SSection>
        <h6>{"Safe Address"}</h6>
        <SAddressDropdownWrapper>
          {!canEdit && address ? (
            <>
              <SBlockie size={40} address={address} />
              <div>{address}</div>
            </>
          ) : (
            <SInput
              maxLength={60}
              value={address}
              onChange={(e: any) => {
                if (e.target) {
                  updateAddress(e.target.value);
                }
              }}
              placeholder={"Safe Address"}
            />
          )}
        </SAddressDropdownWrapper>
      </SSection>
      <SSection>
        <h6>{"Module Address"}</h6>
        <SAddressDropdownWrapper>
          {!canEdit && moduleAddress ? (
            <>
              <SBlockie size={40} address={moduleAddress} />
              <div>{moduleAddress}</div>
            </>
          ) : (
            <SInput
              maxLength={60}
              value={moduleAddress}
              onChange={(e: any) => {
                if (e.target) {
                  updateModuleAddress(e.target.value);
                }
              }}
              placeholder={"Module Address"}
            />
          )}
        </SAddressDropdownWrapper>
      </SSection>
      <SSection>
        <h6>{"Network"}</h6>
        <Dropdown
          selected={chainId}
          options={chains}
          displayKey={"name"}
          targetKey={"chain_id"}
          onChange={updateChain}
        />
      </SSection>
    </React.Fragment>
  );
};
export default AccountDetails;
